import React, { useState, useEffect, useMemo, useRef, Suspense } from 'react'
import { Canvas, Dom, extend, useLoader, useThree, useFrame } from '@react-three/fiber'
import * as THREE from 'three';
import { useSpring, useSprings, a } from '@react-spring/three'
import InViewMonitor from 'react-inview-monitor';
import { useRoutes, A } from "hookrouter";

const ActivityPanel = ({
  setCursor,
  panelColor, //purple
  mainImage, //assets/images/drawdogs-600.gif
  mainUrl, ///game/drawdogs
  mainUrlTarget,  //_self
  btnOneUrl, ///game/drawdogs
  btnOneUrlTarget = "_self", 
  btnOneMode = "play",
  btnOneMsg, //Follow along and Draw Your Own Dogs!
  btnTwoUrl, //  /fun/DrawYourOwnDogs_Worksheet.pdf
  btnTwoMsg,//Download the Draw Your Own Dogs activity sheet!
  btnTwoUrlTarget = "_self", 
  btnTwoMode = "print",
  btnThreeUrl,
  btnThreeMsg,
  btnThreeUrlTarget = "_self", 
  btnThreeMode = "print",

  ...props }) => {

  const [cursor, setCursorState] = useState('default');

  const onMouseEnter = () => {
    setCursorState('pointer');
  };

  const onMouseLeave = () => {
    setCursorState('default');
  };

  return (
    <InViewMonitor
      classNameNotInView='content-holder'
      classNameInView={`content-holder colorize ${panelColor}`}
      toggleClassNameOnInView={true}
    >
      <div>
        <div className="image-holder clickable" 
          onClick={() => window.open(mainUrl, mainUrlTarget)}
        >
          <img src={mainImage} />
        </div>

        <div className="buttonContainer">
          <A 
            href={(btnOneUrl.includes("http") ? '#'  : btnOneUrl)}
            onClick={() => {
              if (btnOneUrl.includes("http")) {
                window.open(btnOneUrl, btnOneUrlTarget);
              }
            }}
          >
            <div 
              onMouseEnter={() => setCursor(btnOneMsg, false)} 
              onMouseLeave={() => setCursor('', true)} 
              className={`roundButton ${btnOneMode}Btn`}
            />
          </A>
          {btnTwoUrl &&
            <A 
              href={(btnTwoUrl.includes("http") ? '#'  : btnTwoUrl)}
              onClick={() => {
                if (btnTwoUrl.includes("http")) {
                  window.open(btnTwoUrl, btnTwoUrlTarget);
                }
              }}
            >
              <div
                onMouseEnter={() => setCursor(btnTwoMsg, false)} 
                onMouseLeave={() => setCursor('', true)} 
                className={`roundButton ${btnTwoMode}Btn secondBtn`}
              />
            </A>
          }

          {btnThreeUrl &&
            <A 
              href={(btnThreeUrl.includes("http") ? '#'  : btnThreeUrl)}
              onClick={() => {
                if (btnThreeUrl.includes("http")) {
                  window.open(btnThreeUrl, btnThreeUrlTarget);
                }
              }}
            >
              <div
                onMouseEnter={() => setCursor(btnThreeMsg, false)} 
                onMouseLeave={() => setCursor('', true)} 
                className={`roundButton ${btnThreeMode}Btn thirdBtn playBtn2`}
              />
            </A>
          }
        </div>
      </div>
    </InViewMonitor>
  );
};

export default ActivityPanel;