
import React, { useState, useEffect } from 'react';
import GridItem from '../components/GridItem';
import {
    useSceneStore,
  } from '../store'
  import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'


export default function BSCawards() {
  const [position, setPosition] = useState(0);

  const { setCursorActive, setFeatured, setModalState, setDomRef, setEvents,setMode,setScene, setScrollPos, setBookState, setTopic, setHomeMessage, setBkgdColor, setCharacter, setTalk, setShuffled, setShowBug, setContentLayout, setBookIndex, setBookOtherIndex,setLoaded } = useSceneStore(state => state.actions)
  const isMobile = useMediaQuery({ query: mediaQueries.mobile })

  function setCursor(msg, boo){
    setCursorActive(boo)
    setTopic(msg)
  }

  useEffect(() => {

  }, []);



  return (
    <div className="bsc-rules-container">

        <h2>Book Song Challenge 2025</h2>

        <p style={{textAlign: "center", maxWidth: "800px", margin: "40px auto"}}>
        It’s time to sing your book song! The 6th Annual Book Song Challenge will take place <a href="https://www.instagram.com/waypastbooks/" target="_blank">@WayPastBooks on Instagram</a> from May 5th-7th, with winners announced on May 9th. See the flyer below for more info and <a href="https://www.instagram.com/waypastbooks/" target="_blank">visit our page</a> to see all entries and winners from the past five years! Contact <a href="mailTo:connect@waypastbooks.com" target="_blank">connect@waypastbooks.com</a> with any questions.
        </p>

        <div style={{ width: "100%", maxWidth: "800px", margin: "40px auto"}}>
        <img style={{width: "100%"}} src="/assets/images/bsc25/bsc25-home-feature.jpg" alt="Book Song Challenge 2025" />
        </div>
        <div className="bsc-date-blocks">

          <div className="date-block">
            <h3 style={{textAlign: "center", width: "100%"}}>Congratulations to our 2024 Grand Prize Winners!!!</h3>
       
          </div>

        </div>

       
             
             
     
        <div className="bsc-winners-grid">
                <a 
                    onMouseEnter={() => setCursor('', false)} 
                    onMouseLeave={() => setCursor('', true)} 
                    className={`clickable`}
                    href="https://www.instagram.com/p/C665DNZvvwn/"
                    target="_blank"
                    >

                    <div className="grid-item">
                        <img
                            src={'/assets/images/bsc24/bsc01-Chase.png'}
                            alt={"Chase"}
                            
                        />
                    </div>
                </a>

                <a 
                    onMouseEnter={() => setCursor('', false)} 
                    onMouseLeave={() => setCursor('', true)} 
                    className={`clickable`}
                    href="https://www.instagram.com/p/C665TZLPG7a/"
                    target="_blank"
                    >

                    <div className="grid-item">
                        <img
                            src={'/assets/images/bsc24/bsc02-Cruz.png'}
                            alt={"Chase"}
                            
                        />
                    </div>
                </a>

                <a 
                    onMouseEnter={() => setCursor('', false)} 
                    onMouseLeave={() => setCursor('', true)} 
                    className={`clickable`}
                    href="https://www.instagram.com/p/C665jeTPhp3/"
                    target="_blank"
                    >

                    <div className="grid-item">
                        <img
                            src={'/assets/images/bsc24/bsc03-Henry.png'}
                            alt={"Chase"}
                            
                        />
                    </div>
                </a>

                <a 
                    onMouseEnter={() => setCursor('', false)} 
                    onMouseLeave={() => setCursor('', true)} 
                    className={`clickable`}
                    href="https://www.instagram.com/p/C6651y0Pe31/"
                    target="_blank"
                    >

                    <div className="grid-item">
                        <img
                            src={'/assets/images/bsc24/bsc04-Jet.png'}
                            alt={"Chase"}
                            
                        />
                    </div>
                </a>

                <a 
                    onMouseEnter={() => setCursor('', false)} 
                    onMouseLeave={() => setCursor('', true)} 
                    className={`clickable`}
                    href="https://www.instagram.com/p/C666GbrP_OD/"
                    target="_blank"
                    >

                    <div className="grid-item">
                        <img
                            src={'/assets/images/bsc24/bsc05-Johnny.png'}
                            alt={"Chase"}
                            
                        />
                    </div>
                </a>

                <a 
                    onMouseEnter={() => setCursor('', false)} 
                    onMouseLeave={() => setCursor('', true)} 
                    className={`clickable`}
                    href="https://www.instagram.com/p/C666XwOPvb0/"
                    target="_blank"
                    >

                    <div className="grid-item">
                        <img
                            src={'/assets/images/bsc24/bsc06-Nora.png'}
                            alt={"Chase"}
                            
                        />
                    </div>
                </a>

                <a 
                    onMouseEnter={() => setCursor('', false)} 
                    onMouseLeave={() => setCursor('', true)} 
                    className={`clickable`}
                    href="https://www.instagram.com/p/C666o_Sv-j-/"
                    target="_blank"
                    >

                    <div className="grid-item">
                        <img
                            src={'/assets/images/bsc24/bsc07-RyanSadieLondon.png'}
                            alt={"Chase"}
                            
                        />
                    </div>
                </a>

                <a 
                    onMouseEnter={() => setCursor('', false)} 
                    onMouseLeave={() => setCursor('', true)} 
                    className={`clickable`}
                    href="https://www.instagram.com/p/C66623tPSch/"
                    target="_blank"
                    >

                    <div className="grid-item">
                        <img
                            src={'/assets/images/bsc24/bsc08-Sage.png'}
                            alt={"Chase"}
                            
                        />
                    </div>
                </a>

                <a 
                    onMouseEnter={() => setCursor('', false)} 
                    onMouseLeave={() => setCursor('', true)} 
                    className={`clickable`}
                    href="https://www.instagram.com/p/C667D1kvLF0/"
                    target="_blank"
                    >

                    <div className="grid-item">
                        <img
                            src={'/assets/images/bsc24/bsc09-Sarena.png'}
                            alt={"Chase"}
                            
                        />
                    </div>
                </a>

                <a 
                    onMouseEnter={() => setCursor('', false)} 
                    onMouseLeave={() => setCursor('', true)} 
                    className={`clickable`}
                    href="https://www.instagram.com/p/C667Sm1vLwV/"
                    target="_blank"
                    >

                    <div className="grid-item">
                        <img
                            src={'/assets/images/bsc24/bsc10-Theo.png'}
                            alt={"Chase"}
                            
                        />
                    </div>
                </a>
        </div>
       
     
        <h3>The Winners Have Received</h3>
        <p style={{textAlign: 'center'}}>A <b>$1,000 Book Gift Card</b> for their school or library!<br />A <b>$75 Book Gift Card</b> for themselves!
         
        </p>
      

        <div className="green clickable" style={{
                    borderRadius: '15px',
                    overflow: 'hidden',
                    height: '315px',
                    outline: '20px solid #0b5542',
                    margin: '60px 0px 30px'
                }}>
                        <iframe width={isMobile ? '300' : '560'} height="315" src="https://www.youtube.com/embed/Mzy1FQ-ZUhI?si=X7hqCiAYz3W8uV_v" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      
                </div>

      
       
  
    </div>
  );
}