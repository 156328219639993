// src/components/Overlay.js

import React, { useState, useEffect } from 'react';
import {
  useSceneStore,

} from '../store'
import GridItem from '../components/GridItem';
import CloseButton from '../components/CloseButton';
import BSCawards from '../components/BSCawards';



const BscOverlay = () => {
 
  const { isCategoryOverlayVisible} = useSceneStore(state => state)
  const { setIsCategoryOverlayVisible} = useSceneStore(state => state.actions)

  
  useEffect(() => {
    setIsCategoryOverlayVisible(true);
      
  }, []);

  const toggleOverlay = () => {

    setIsCategoryOverlayVisible(false);
  };



  return (
    <div className={`overlay bsc-overlay ${isCategoryOverlayVisible ? 'active' : ''}`}>
       <CloseButton
            toggleOverlay={toggleOverlay}
        />
         <div className="overlay-content">
         
   
          <BSCawards />

          <div className='overlay-headline'>
            <h2 style={{
              marginBottom: '10px'
            }}>More Book Song Challenge Fun</h2>
            <p style={{
              textAlign: 'center',
              color: 'white',
              marginTop: '20px',
              marginBottom: '40px'
            }}>Check out entries from past Book Song Challenges</p>
          </div>
          <div className="grid-content">

                <GridItem 
                  title="Book Song Challenge 2025 Flyer"
                  image="/assets/images/BookSongChallenge2025_Flyer_600.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2025/04/BookSongChallenge2025_Flyer.pdf"
                  target="_blank"
                />

                <GridItem 
                  title="Book Song Challenge 2024 Flyer"
                  image="/assets/images/BSC24_AnnouncementFlyer-poster.jpg"
                  url="/assets/images/pdf/BookSong2024-announcement.pdf"
                  target="_blank"
                />

                <GridItem 
                  title="Book Song Challenge 2023 Winners"
                  image="/assets/images/BSC23_Winners-Thumbnail.jpg"
                  url="https://youtu.be/YIb6T3A2E64"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="Book Song Challenge 2023 Flyer"
                  image="/assets/images/BSC23_AnnouncementFlyer-poster.jpg"
                  url="/assets/images/pdf/BSC23_AnnouncementFlyer.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Book Song Challenge 2022 Winners"
                  image="/assets/images/bsc22-winners-poster.jpg"
                  url="https://youtu.be/cKQ35QO-udE"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />



              <GridItem 
                  title="Book Song Challenge 2022 Flyer"
                  image="/assets/images/BSC22_AnnouncementFlyer02-poster.jpg"
                  url="/assets/images/pdf/BSC22_AnnouncementFlyer02.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Book Song Challenge 2021 Highlights"
                  image="/assets/images/bsc2021-highlights-poster.jpg"
                  url="https://youtu.be/g-2EbmdzxLE"
                 // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="Book Song Challenge 2021 Flyer"
                  image="/assets/images/BSC21_Flyer_v2_with_Abigail_white-poster.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/12/BSC21_Flyer_v2-with-Abigail.pdf"
                  target="_blank"
                />



              <GridItem 
                  title="Book Song Challenge 2020 Flyer"
                  image="/assets/images/BSC20_Flyer-poster.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/12/BSC-2020-Flyer.pdf"
                  target="_blank"
                />
            
          </div>
        </div>
      </div>

  );
};

export default BscOverlay;