import React, { useState, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
//import { Document } from 'react-pdf/dist/esm/entry.webpack';


//import { Page, Text, View, Document, StyleSheet, pdfjs } from '@react-pdf/renderer';
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import {
  useAudioStore,
  useSceneStore,
} from '../store'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/*
const styles = StyleSheet.create({
  section: {
    width: 200,
    '@media max-width: 400': {
      width: 300,
    },
    '@media orientation: landscape': {
      width: 400,
    },
  }
});
*/

const PdfViewer = ({ url }) => {

  const { setCursorActive,  setTopic } = useSceneStore(state => state.actions)
  
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const mobile = window.innerWidth < 700;
  const percent = (mobile) ? .6 : .8;

  useEffect(() => {
   // alert(url)
   
 }, []);

  function onDocumentLoadSuccess({ numPages }) {
    console.log('numPages', numPages)
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function setCursor(msg, boo){
    setCursorActive(boo)
    setTopic(msg)
  }


  return (
    <div className="printable">
      <Document
        
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page className="rounded-corners" pageNumber={pageNumber} height={window.innerHeight*percent} />
        
      </Document>
      
      <div className="page-controls">

        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          ‹
        </button>
        <span>{pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</span>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          ›
        </button>
      </div>
     
    
       
        <div className="buttonContainer">
          <a download={url.substr(url.lastIndexOf('/') + 1)} href={url} title="Download">
            <div className="roundButton downloadBtn"
                onMouseEnter={() => setCursor('Download', false)} 
                onMouseLeave={() => setCursor('', true)} 
            ></div>
          </a>
          <a href="" onClick={() => window.print()} title="Print">
          <div className="roundButton printBtn"
          onMouseEnter={() => setCursor('Print', false)} 
          onMouseLeave={() => setCursor('', true)} 
          ></div>
          </a>
        </div>
        
     
    </div>
  );
}

export default PdfViewer;