import React, { useEffect, useState, useRef } from "react";
import CanvasScene from "./components/CanvasScene";
import Cursor from "./components/Cursor";
//import Modal from "./components/Modal";
import Nav from "./components/Nav";
//import AutoplayChecker from "./components/AutoplayChecker";
import NavMobile from "./components/NavMobile";
//import ScrollWindow from "./components/ScrollWindow";
//import Suspended from "./components/Suspended";
//import TestControls from "./components/TestControls";
import AudioProvider from "./components/AudioProvider";
//import WelcomeAudio from "./components/WelcomeAudio";
import Overlay from "./components/Overlay";
import CategoryBar from "./components/CategoryBar";
import useSound from 'use-sound';
//import Routes from "./Routes"

import { useRoutes, usePath, A } from "hookrouter";
import routes from "./router";

import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from './config'
import audioMap from './resources/audio';





import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


import {
  useAudioStore,
  useSceneStore,
} from './store'

import useStore from './store/scroll'

import "./styles.scss";


export default function App() {
  
  const { scrollRef, messages, mode,featured,  modalState, scene, pages,top, sections, scrollPos, bookState, topic, bkgdColor, character, bookIndex, loaded, defaultHeight, showBug, contentLayout } = useSceneStore(state => state)
  const { setScene,setMode,setModalState,setFeatured, setFilterGrid, setScrollPos, setBookState, setTopic, setHomeMessage, setBkgdColor, setCharacter, setTalk, setShuffled, setShowBug, setContentLayout, setBookIndex, setLoaded, setDefaultHeight } = useSceneStore(state => state.actions)
  
  const wasWelcomed = useAudioStore(state => state.wasWelcomed)
  const wasWelcomedToVideo = useAudioStore(state => state.wasWelcomedToVideo)
  const wasWelcomedToFun = useAudioStore(state => state.wasWelcomedToFun)


  const [playWelcome, endWelcome] = useSound(audioMap.welcome, {
    volume: 1,
    interrupt: true,
  });

  const routeResult = useRoutes(routes);
  const suspended = useAudioStore(state => state.suspended)
 // const { playSound } = useAudioStore(state => state.actions)

  const sectionActive = useStore(state => state.sectionActive)

 
  //const location = useLocation();
  const mobile = window.innerWidth < 700;

  const isMobile = useMediaQuery({ query: mediaQueries.mobile })
  const isTablet = useMediaQuery({ query: mediaQueries.tablet })
  const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

  const location = usePath();


/*
  function menuClick(e, val){
      console.log('menuClick')
      setMode('grid')
      setFilterGrid(val)
      setContentLayout('grid')
      scrollToTop();
     // scrollArea.current.scrollTop = 0;
      
  }
  */

  function scrollToTop() {
    // console.log(scrollArea.current.scrollHeight)
    setMode('scroll')
    setContentLayout('spiral')
     scroll.scrollTo(0,{

       smooth: true,
       containerId: 'scroller',
      // offset: 50, // Scrolls to element + 50 pixels down the page
     
     });
   }


  function scrollToBooks() {
    console.log('scrollToBooks')
    setMode('scroll')
    setContentLayout('spiral')
    //console.log(scrollArea.current.scrollHeight*.675 +' vs '+-markerRef.current.parent.position.y)
    const decimal = (isMobile)?.65:.675;
    scroll.scrollTo(defaultHeight*decimal,{

      delay: 100,
      smooth: true,
      containerId: 'scroller',
     // offset: 50, // Scrolls to element + 50 pixels down the page
    
    });
  }

  function scrollToMore() {
    setMode('scroll')
      setContentLayout('spiral')
     

    scroll.scrollTo(defaultHeight*.9,{
       smooth: true,
       containerId: 'scroller',
      // offset: 50, // Scrolls to element + 50 pixels down the page
     
     });
   }

   function closeModal(){
    setModalState(false)
    setFeatured(null)
  }
/*
  function checkAutoplay() {
    // Create an AudioContext
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    // Load an audio file
    const audioElement = new Audio('https://waypastbooks.com/assets/audio/welcome.mp3');

    // Connect the audio element to the AudioContext
    const source = audioContext.createMediaElementSource(audioElement);

    // Check if audio can be autoplayed
    audioContext.resume().then(() => {
      // Autoplay is allowed
      console.log('Autoplay is allowed');
     // playWelcome();
    }).catch((error) => {
      // Autoplay is not allowed
      console.error('Autoplay is not allowed', error);
    });

    // Start the audio playback (you can use this to test autoplay)
    audioElement.play();
    
  }
*/



  
const deviceType = () => {
    if (isMobile){
      return 'mobile';
    }else if (isTablet){
      return 'tablet';
    }else if (isDesktop){
      return 'desktop';
    }
  }

  useEffect(() => {

  //  checkAutoplay();

  },[])


  useEffect(() => {

  
    setBkgdColor(sectionActive)
    setCharacter(sectionActive)
   // setTalk(sectionActive)
    setHomeMessage(messages[sectionActive])

    if (sectionActive === 0){
      setShowBug(false)
    }else{
      setShowBug(true)
    }    

  },[sectionActive])

  
  return (
    <>
  
     
   {/*  <AudioProvider />  */}
    {(location.includes('/drawdogs') === false) &&
       <>
        <CanvasScene />
        {(location.includes('/fun-and-games') || location.includes('/videos') || location.includes('/category')) &&
            <CategoryBar />
        }
        <Cursor />   
      </>
    }
       
            {routeResult}
            {isMobile &&
               <NavMobile />
            }
            
            <div className="backPanel" style={{backgroundColor: bkgdColor}}></div>
            <Nav />               
            <Overlay />
            <div className="bsc-banner">
              <a href="/category/book-song-challenge" style={{textDecoration: 'none'}}>
              <div className="bsc-banner-content">
                <p>Book Song Challenge 2025: Coming May 5 - 7</p>
               </div>
               </a>
            </div>


         {/*   <ScrollWindow  />*/} 

       
    
    
       
    

      
 
  

      



     {/*  <TestControls checkSlides={checkSlides}/> */}
    </>
  );
}